<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <!-- <a class="logo" (click)="navigateHome()" style="cursor: pointer"
      ><img src="../../../../../assets/images/mainLogo.png"
    /></a> -->
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'form']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name || projectName"
        [picture]="user?.photo"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
