import {
  Injectable,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NbAuthJWTToken,
  NbAuthModule,
  NbAuthService,
  NbAuthSimpleToken,
  NbDummyAuthStrategy,
  NbPasswordAuthStrategy,
} from "@nebular/auth";
import { NbSecurityModule, NbRoleProvider } from "@nebular/security";
import { Observable, of as observableOf } from "rxjs";

import { throwIfAlreadyLoaded } from "./module-import-guard";
import { environment } from "../../environments/environment";
import { LayoutService } from "./utils";
import { map } from "rxjs/operators";

const socialLinks = [
  {
    url: "https://github.com/akveo/nebular",
    target: "_blank",
    icon: "github",
  },
  {
    url: "https://www.facebook.com/akveo/",
    target: "_blank",
    icon: "facebook",
  },
  {
    url: "https://twitter.com/akveo_inc",
    target: "_blank",
    icon: "twitter",
  },
];

@Injectable({
  providedIn: "root",
})
export class NbSimpleRoleProvider extends NbRoleProvider {
  constructor(private authService: NbAuthService) {
    super();
  }

  getRole(): Observable<string> {
    return this.authService.onTokenChange().pipe(
      map((token: NbAuthJWTToken) => {
        return token.isValid()
          ? token.getPayload()["userrole"] || "user"
          : "user";
      })
    );
  }
}

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: "email",
        token: {
          class: environment.tokenTypeBearer
            ? NbAuthJWTToken
            : NbAuthSimpleToken,
          key: "data.jwtToken",
        },
        baseEndpoint: environment.API_HOST,
        login: {
          endpoint: "/auth/login",
          method: "post",
          redirect: {
            success: "/home/",
            failure: null, // stay on the same page
          },
        },
      }),
    ],
    forms: {
      login: {
        socialLinks: [],
        rememberMe: false,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      user: {
        view: ["form"],
        create: ["form"],
        edit: ["form"],
      },
      admin: {
        view: "*",
        create: "*",
        remove: "*",
        edit: "*",
      },
      driver: {
        view: ["form"],
      },
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },

  LayoutService,
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
